<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <router-link :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1"
            to="/products">
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
            {{ $t('new_product') }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">
            {{ $t('edit_product') }}
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 relative">
           <loading :active.sync="loading" :is-full-page="fullPage"></loading>

          <div  class="grid lg:grid-cols-2 gap-6">
            <template v-if="currentUser.type !== 'Seller'">
                <div class="border seller-select h-10 hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="seller" class="bg-white text-gray-700 px-1">  {{$t('Type produit')}}
                          <span :class="$colors.required">*</span></label>
                        </p>
                      </div>    
                      <p>
                          <v-select :disabled="formData._id && formData.type && (!formData.seller.source || formData.seller.source && formData.seller.source !== 'AFFILIATECOD')?true:false" @input="setTypeProduct"  v-model="formData.type" :options="types" class="capitalize"></v-select>
                      </p>         
                  </div>  
            </template>
            <template v-if="currentUser.type === 'Seller' || formData.type">
              <div v-if="currentUser && currentUser.type && currentUser.type != 'Seller'" class="border seller-select h-10 hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="seller" class="bg-white text-gray-700 px-1">  {{$t('seller')}}
                        <span :class="$colors.required">*</span></label>
                      </p>
                    </div>    
                    <p>
                        <v-select :disabled="formData._id && formData.seller?true:false" label="fullName" @search="searchSellers($event,'hh')" v-model="formData.seller" :options="sellers"></v-select>
                    </p>         
                </div>  
                <div
                class="border hover:border-green-500  border-gray-600  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="name" class="bg-white text-gray-700 px-1"
                      >{{$t('name')}} <span :class="$colors.required">*</span></label
                    >
                  </p>
                </div>
                <p>
                  <input
                    id="name"
                    autocomplete="false"
                    placeholder="Name ...."
                    type="text"
                    v-model="formData.name"
                    class="py-2 text-xs px-2 outline-none block h-full w-full"
                  />
                </p>
                </div>
                <div v-if="formData.type && formData.type === 'affiliate'" class="border seller-select h-10 hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="seller" class="bg-white text-gray-700 px-1">  {{$t('Warehouses')}}
                          <span :class="$colors.required">*</span></label>
                        </p>
                      </div>    
                      <p>
                          <v-select label="name" @input="setSelectedCountries" v-model="formData.warehouses" :options="warehouses" multiple>
                            <template slot="option" slot-scope="option">
                                <img
                                  class="flag-img"
                                  :src="$f.getCountryCode(option.country)"
                                />
                                {{ option.name }}
                              </template>
                          </v-select>
                      </p>   
                            
                  </div> 
                <div v-if="formData.type && ['normal','POD'].includes(formData.type) || !formData.type"
                  class="border h-10 hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="verificationLink"
                        class="bg-white text-gray-700 px-1"
                        > {{ $t('verification_link') }} <span :class="$colors.required">*</span>
                      </label>
                    </p>
                  </div>
                  <p>
                    <input
                      id="verificationLink"
                      autocomplete="false"
                      tabindex="0"
                      type="text"
                      v-model="formData.verificationLink"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                    />
                  </p>
                </div>


                <div
                  class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="description" class="bg-white text-gray-700 px-1"
                        > {{$t('description')}} <span :class="$colors.required">*</span>
                      </label>
                    </p>
                  </div>
                  <p>
                    
                    <input v-if="formData.type && ['normal','POD'].includes(formData.type) || !formData.type"
                      id="description"
                      autocomplete="false"
                      tabindex="0"
                      type="description"
                      v-model="formData.description"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                    />
                    <vue-editor v-else v-model="formData.description"></vue-editor>
                    
                  </p>
                </div>

                <div v-if="formData.type && ['normal','POD'].includes(formData.type)|| !formData.type"
                  class="border hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="code" class="bg-white text-gray-700 px-1"
                        >{{$t('code')}} </label
                      >
                    </p>
                  </div>
                  <p>
                    <vue-tags-input
                      v-model="tag"
                      :tags="tagCodes"
                      placeholder="Add code"
                      @tags-changed="newTags => tagCodes = newTags"
                      class="text-xs text-gray-900 outline-none block h-full w-full max-w-full p-0"
                    />
                  </p>
                </div>
              
                <div v-if="formData.type && ['normal','POD'].includes(formData.type) || !formData.type"
                  class="border h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="code" class="bg-white text-gray-700 px-1"
                        >Variant code </label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      id="code"
                      autocomplete="false"
                      placeholder="Variant code"
                      type="text"
                      v-model="formData.variant_code"
                      class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                    />
                  </p>
                </div>

               
                <div v-if="formData.type && formData.type == 'affiliate'"
                  class="border h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="code" class="bg-white text-gray-700 px-1">Catégorie </label>
                    </p>
                  </div>
                  <p>
                    <v-select v-model="formData.category" placeholder="Catégorie ..." :options="categories" ></v-select>
                  </p>
                </div>
                

                <div   v-if="formData._id && currentUser && currentUser.type && currentUser.type != 'Seller'"
                  class="border hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                >
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label for="Placement" class="bg-white text-gray-700 px-1">{{$t('Placement de produit')}} </label>
                    </p>
                  </div>
                  <div v-for="(item, key) in formData.details" :key="key"  class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 flex flex-wrap items-stretch w-full mb-2 relative h-15 bg-white items-center rounded">
                    <div class="flex -mr-px justify-center p-1">
                      <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                        <span class="select-none ml-2"> <img class="flag-img" :src="item.warehouse.flag"/></span>
                      </span>
                    </div>
                    <input type="text" placeholder="..."  v-model="item.place" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative outline-none">
                  </div>
                </div>

                <div v-if="formData._id && currentUser && currentUser.type && currentUser.type !== 'Seller'" class="border hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div
                    class="-mt-4 absolutes tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="outOfStock"
                        class="bg-white text-gray-700 px-1"
                        >{{$t('out_of_stock')}}
                      </label>
                    </p>
                  </div>
                  <p v-for="(item, key) in formData.details" :key="key" class="flex items-center justify-center w-full py-2">
                    <!-- Toggle Button -->
                    <label :for="'toogle'+key" class="flex items-center cursor-pointer">
                        <div class="select-none mr-5"> <img class="flag-img" :src="$f.getCountryCode(item.warehouse.country)"/> {{item.warehouse.name}}</div>
                      <div class="relative">
                        <input :id="'toogle'+key"  type="checkbox" class="hidden" v-model="item.outOfStock" />
                        <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                        <div :class="'toggle__dot-'+key" class="absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                      </div>
                      <div class="ml-3 text-gray-700 capitalize">{{ $t(item.outOfStock) }}</div>
                    </label>
                    </p>
                </div>

                <div class="border hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label for="Picture" class="bg-white text-gray-700 px-1"> {{$t('picture')}} <span :class="$colors.required">*</span></label>
                    </p>
                  </div>
                  <!--<p>
                    <input id="Picture" autocomplete="false" tabindex="0" type="text" v-model="formData.picture" class="py-2 text-xs px-2 outline-none block h-full w-full"/>
                  </p>-->
                  <div class="flex w-full mt-1 mb-1  items-center justify-center bg-grey-lighter">
                    <label class="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                        <svg  v-if="formData.picture==''" class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <img v-if="formData.picture!=''" :src="formData.picture" alt="" />
                        <span v-if="formData.picture==''" class="mt-2 text-base leading-normal"> {{$t('select_image')}}</span>
                        <input type='file' @change="handleImage($event)" class="hidden" accept="image/*"   />

                        <span v-if="formData._id && !formData.picture" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                          <p>La taille de image doit pas dépasser 500 ko</p>
                        </span>
                    </label>
                </div>
                </div>

            </template>
           
          
          </div>

          <div v-if="formData._id || (formData.type && formData.type == 'affiliate' && formData.details.length>0)" class="flex mt-10 mb-5 items-center text-center">
             <hr class="border border-gray-500 -gray-300 border-1 w-full rounded-md">
                <label class="block font-medium font-medium text-base cursor-pointer text-blue-500  w-full" @click="faq_selectedF">
                    <div class="bg-indigo-100 mr-4 inline-block text-indigo-400 w-8 h-8 md:w-10 md:h-10 rounded-md  items-center justify-center font-bold text-lg font-display">
                      <span class="align-middle"><i class="large font-bold material-icons align-bottom">settings</i></span>
                    </div>
                    <span class="font-medium text-base text-blue-700 dark:text-blue-800 align-middle"> 
                      {{ getMessagePopup(formData.type) }}
                    </span>
                </label>
              <hr class="border border-gray-500 -gray-300 border-1 w-full rounded-md">
          </div>
          

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              @click="save">
              {{ $t('save') }}
            </button>
          </div>

        </div>
      </div>

    </div>


    <!--Modal Up-Sells setting -->
    <vue-modal-2 v-if="formData.details && formData.details[0] && formData.details[0].warehouse && formData.details[0].warehouse.name" name="ModalSettings" :headerOptions="{title: getMessagePopup(formData.type) }" 
         :noFooter="true" modalSize="lg" @on-close="close('')">
        <div class="bg-white rounded-lg">
            <div class="bg-white px-4 pt-0 pb-1 mb-20">
              

              <tabs :options="{ defaultTabHash: 'tab_'+formData.details[0].warehouse.country }">
              
                <tab  v-for="(pr, key) in formData.details.filter(item => item.warehouse && item.warehouse.is_primary)" :key="key" :id="'tab_'+pr.warehouse.country" :name="pr.warehouse.name">

                  <div v-if="formData.type === 'POD'" class="product-details">
                    <div class="no-js-hidden product-option has-default">
                      <fieldset class="js product-form__input product-form__swatch clearfix">
                              <legend class="form__label">
                                  Color:
                                  <!-- <span data-header-option="">Gray</span>  -->
                              </legend>
                              <template v-for="(variant,index) in pr.POD.variants">
                                  <input class="product-form__radio" type="radio" :id="index" name="Color" :value="variant.image" :checked="POD.index === index?true:false"  :data-variant-id="index">
                                  <label @click="selectPOD(index,variant.sizes)" class="product-form__label available" name="Color" :for="'option-Color-0'+index" :data-variant-id="index">
                                        <span class="pattern" :style="`background-color: gray; background: url(${variant.image}); background-size: cover;`"></span>
                                  </label>
                              </template>
                        </fieldset>
                        <fieldset v-if="POD.dataSelected.length>0" class="js product-form__input clearfix" data-product-attribute="set-rectangle" data-option-index="1">
                              <legend class="form__label">
                                  Size:
                                  <!-- <span data-header-option="">XS</span>  -->
                              </legend>
                              <template v-for="(size,index) in POD.dataSelected">
                                  <input class="product-form__radio" type="radio" :id="size.id" name="Size" :value="size.name" :data-variant-id="size.id">
                                  <label class="product-form__label available" :for="'option-Size-'+index" :data-variant-id="size.id">
                                      <span class="text">{{ size.name }}</span>
                                      <span class="text price">{{ size.price }}<sup class="ml-1">{{pr.warehouse.currency}}</sup></span>
                                  </label>
                              </template>
                            
                        </fieldset>
                      
                    </div>
                  </div>
                 

                
                    <div v-if="['normal','affiliate'].includes(formData.type)" class="grid lg:grid-cols-2 gap-6"> 
                      
                        <div class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="price" class="bg-white text-gray-700 px-1">{{formData.type && ['normal','POD'].includes(formData.type)?$t('Prix ​​unitaire'):'Prix ​​de base'}} <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <div class="w-full rounded-md inline-flex border">
                            <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-300 w-1/4 bg-gray-200"><span> {{ pr.warehouse.currency }}</span></div>
                            <input type="number" v-model="pr.price"  min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                          </div>
                        
                        </div>
                        
                        <div v-if="formData.type && formData.type == 'affiliate'" class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="price" class="bg-white text-gray-700 px-1">Commission<span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <div class="w-full rounded-md inline-flex border">
                            <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-300 w-1/4 bg-gray-200"><span> {{ pr.warehouse.currency }}</span></div>
                            <input type="number" v-model="pr.commission"  min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                          </div>
                        </div>
                    </div>

                    <template v-if="formData.type && formData.type == 'affiliate'">
                      <div class="grid lg:grid-cols-1 gap-6 mt-5"> 
                        <div  class="border  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                              <div
                                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                              >
                                <p>
                                  <label
                                    for="drive"
                                    class="bg-white text-gray-700 px-1"
                                    >Lien Landing Page <span :class="$colors.required">*</span>
                                  </label>
                                </p>
                              </div>
                              <p>
                                <textarea id="drive" type="text" rows="2"  placeholder="Link ...." v-model="pr.affiliate.landingPage" class="py-2 text-xs px-2 outline-none block h-full w-full"></textarea>
                              </p>
                          </div>

                          <div  class="border hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                              <div
                                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                              >
                                <p>
                                  <label
                                    for="drive"
                                    class="bg-white text-gray-700 px-1"
                                    > Contenus publicitaires (Link drive) <span :class="$colors.required">*</span>
                                  </label>
                                </p>
                              </div>
                              <p>
                                <textarea id="drive" type="text" rows="2"  placeholder="Link ...." v-model="pr.affiliate.linkDrive" class="py-2 text-xs px-2 outline-none block h-full w-full"></textarea>
                              </p>
                            </div>
                      </div>
                      <div class="grid lg:grid-cols-2 gap-6 mt-5"> 
                            <div class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                <p>
                                    <label for="price" class="bg-white text-gray-700 px-1">{{$t('Confirmation rate')}} <span :class="$colors.required">*</span></label>
                                </p>
                              </div>
                              <div class="w-full rounded-md inline-flex border">
                                <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-300 w-1/4 bg-gray-200"><span> % </span></div>
                                <input type="number" v-model="pr.affiliate.confirmationRate"  min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                              </div>
                            </div>
                            <div class="border hover:border-green-500  border-gray-600  hover:border-green-500  border-gray-600  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                <p>
                                    <label for="price" class="bg-white text-gray-700 px-1">{{$t('Delivery rate')}} <span :class="$colors.required">*</span></label>
                                </p>
                              </div>
                              <div class="w-full rounded-md inline-flex border">
                                <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-300 w-1/4 bg-gray-200"><span> % </span></div>
                                <input type="number" v-model="pr.affiliate.deliveryRate"  min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                              </div>
                            </div>
                      </div>
                    </template>

                    <div v-if="['normal'].includes(formData.type)" class="flex mt-5 mb-5 items-center text-center"> 
                          <hr class="border border-gray-500 -gray-300 border-1 w-full rounded-md">
                          <label class="block font-medium font-medium text-base cursor-pointer text-blue-500  w-full">
                              <span class="font-medium text-base text-blue-700 dark:text-blue-800 align-middle">Upsell paramètres</span>
                          </label>
                         <hr class="border border-gray-500 -gray-300 border-1 w-full rounded-md">
                    </div>

                    <div v-if="['normal'].includes(formData.type)" class="invoice-item-list">
                        <table class="table invoice-table  invoice-table-edit">
                                <thead class="thead">
                                    <tr>
                                      <th class="invoice-detail-margin">&nbsp;</th>
                                      <th class="invoice-detail-quantity bg-gray-50">Quantity</th>
                                      <th class="invoice-detail-total bg-gray-50">Prix</th>
                                      <th v-if="formData.type && formData.type == 'affiliate'" class="invoice-detail-total bg-gray-50">Commission</th>
                                    </tr>
                                </thead>
                                <tbody class="invoice-items">
                                  <tr class="item-row item-row-1 with-tax" v-for="(upsel, index) in pr.upsells" :key="index">
                                      <td class="item-row-actions" style="text-align: center !important;vertical-align: middle !important;"><div v-if="index === (pr.upsells.length - 1)" class="confirm-delete-button"><button @click="removeUpsel(key,index)" title="Remove Item" class="btn btn-remove"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></button></div></td>
                                      
                                      <td data-label="Quantity" class="item-row-quantity">
                                            <div class="w-full rounded-md inline-flex border">
                                              <div class="flex items-center font-bold text-s  w-100px justify-center border border-gray-400 w-1/4 bg-gray-100"><svg class="svg-inline--fa fa-plus fa-w-14 " xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 0h11v12h-3.55c-.933 0-1.356.575-1.356 1.109 0 .332.155.672.438.953.294.284.468.388.468.786 0 .47-.534 1.152-1.5 1.152s-1.5-.682-1.5-1.152c0-.397.174-.501.469-.785.282-.281.438-.621.438-.953 0-.534-.424-1.109-1.356-1.109h-3.551v-4.551c0-.535.137-.44.454-.109.245.263.616.659 1.394.659 1.039 0 2.152-1.004 2.152-2.5s-1.113-2.5-2.152-2.5c-.777 0-1.148.396-1.394.659-.317.333-.454.424-.454-.109v-3.55zm-8.45 12c.534 0 .441-.137.108-.454-.262-.245-.658-.616-.658-1.394 0-1.039 1.004-2.152 2.5-2.152s2.5 1.113 2.5 2.152c0 .777-.396 1.148-.658 1.394-.331.315-.427.454.108.454h3.55v-4.55c0-.932.575-1.357 1.109-1.357.332 0 .672.156.953.438.285.296.389.469.786.469.47 0 1.152-.534 1.152-1.5s-.682-1.5-1.152-1.5c-.396 0-.501.173-.785.469-.281.282-.621.438-.953.438-.535 0-1.11-.425-1.11-1.357v-3.55h-12v12h4.55zm7.45 8.45c0-.533-.137-.442-.454-.109-.245.263-.616.659-1.394.659-1.039 0-2.152-1.004-2.152-2.5s1.113-2.5 2.152-2.5c.777 0 1.148.396 1.394.659.317.331.454.425.454-.109v-3.55h-3.55c-.933 0-1.356-.575-1.356-1.109 0-.332.155-.672.438-.953.294-.284.468-.388.468-.786 0-.47-.534-1.152-1.5-1.152s-1.5.682-1.5 1.152c0 .397.174.501.469.785.282.281.438.621.438.953-.001.535-.425 1.11-1.357 1.11h-4.55v11h12v-3.55zm8.45-7.45c-.534 0-.441.137-.108.454.262.245.658.616.658 1.394 0 1.039-1.004 2.152-2.5 2.152s-2.5-1.113-2.5-2.152c0-.777.396-1.148.658-1.394.331-.315.427-.454-.108-.454h-3.55v3.55c0 .932-.575 1.357-1.109 1.357-.332 0-.672-.156-.953-.438-.285-.296-.389-.469-.786-.469-.47 0-1.152.534-1.152 1.5s.682 1.5 1.152 1.5c.396 0 .501-.173.785-.469.281-.282.621-.438.953-.438.534 0 1.109.425 1.109 1.357v3.55h11v-11h-3.549z"/></svg></div>
                                              <input type="number" disabled v-model="upsel.quantity" min="0" class="w-full border border-gray-400  focus:outline-none  bg-white text-gray-700 focus:text-gray-600 p-2">
                                          </div>
                                      </td>
                                      <td data-label="Amount" class="item-row-amount">
                                              <div class="w-full rounded-md inline-flex border">
                                              <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-400 w-1/4 bg-gray-100">
                                                  <span v-if="pr.warehouse"> {{pr.warehouse.currency}}</span>
                                              </div>
                                              <input type="number" v-model="upsel.price"  min="0" class="w-full focus:outline-none border border-gray-400  bg-white  text-gray-700 focus:text-gray-600 p-2">
                                          </div>
                                      </td>
                                      <td v-if="formData.type && formData.type == 'affiliate'" data-label="Amount" class="item-row-amount">
                                              <div class="w-full rounded-md inline-flex border">
                                              <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-400 w-1/4 bg-gray-100">
                                                  <span v-if="pr.warehouse"> {{pr.warehouse.currency}}</span>
                                              </div>
                                              <input type="number" v-model="upsel.commission"  min="0" class="w-full focus:outline-none border border-gray-400  bg-white  text-gray-700 focus:text-gray-600 p-2">
                                          </div>
                                      </td>
                                  </tr>
                                  <tr class="item-row item-row-n">
                                      <td class="item-row-actions" colspan="3"><button id="invoice-item-add" @click="addUpsell(key)" title="Add Item" class="btn btn-prime animation-pulse"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></button></td>
                                  </tr>
                                </tbody>
                          </table>
                    </div>
                   
                   
                </tab>
              
              </tabs>
            
            </div>    
            <div class="modal-footer px-5 border0-t text-right">
                <a href="#" @click="close('')" :class="loading?'noselect':''" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Fermer</a>
                <a v-if="['normal','affiliate'].includes(formData.type)" href="#" @click="save" :class="loading?'noselect':''" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative">Enregistrer</a>
            </div>
        </div>
    
    </vue-modal-2>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueTagsInput,
    VueEditor
  },
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object }
  },
  data() {
    return {
      formData: {
        name: "",
        code: "",
        price: 0,
        description: "",
        quantity: {
          inStock: 0,
          total: 0,
        },
        details: [{ price: 0,warehouse:null }],
        verificationLink: "",
        outOfStock: false,
        picture: "",
        seller: "",
        type:'',
        prices:[{price:0,warehouse:null}],
        warehouses:[]
      },
      loading: false,
      fullPage: false,
      //currentUser: {},
      sellers: [],
      categories: [],
      products: [],
      sellersExist: false,
      //idWharhouse:null,
      currency:"",
      countryW:"",
      faq_selected:false,
      tag:'',
      tagCodes:[],
      types:['normal','affiliate'],
      categories: ["Électronique", 'Vêtements', 'Maison et jardin', 'Alimentation et boissons', 'Santé et beauté', 'Sports et loisirs', 'Automobile et moto', 'Bébé et enfant', 'Animaux de compagnie', 'Électroménager', 'Outils et bricolage', 'Bijoux et montres', 'Cosmétique'],
      warehouses:[],
      POD:{index:100,dataSelected:[]}
    };
  },

  async mounted() {
    console.log('wareh-----------------', this.idWharhouse)
    // await this.getUser();
    // await this.getwharhouseId();
    await this.getContryW();
    this.dataToEdit();
    // this.getCategories();

    if (this.currentUser && this.currentUser.type && this.currentUser.type != "Seller") await this.getSellers();
    else this.formData.seller = this.currentUser._id;

    this.getProducts();

  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({ user: _.pick(this.currentUser, ['countries']) })
    },
  },
  watch: {
    'warhouseSelected': async function (oldVal, newVal) {
      //await this.getwharhouseId();


    },
    'idWharhouse': async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.getContryW();
      if (this.currentUser && this.currentUser.type && this.currentUser.type != "Seller") await this.getSellers();
      else this.formData.seller = this.currentUser._id;

    }
  },
  methods: {
    getMessagePopup(action){
       let dataMess="";
       if(action === 'normal') dataMess='Prix ​​unitaire + Upsell paramètres'
       if(action === 'POD') dataMess='POD paramètres'
       if(action === 'affiliate') dataMess='Affiliate paramètres'
       return dataMess;
    },
    selectPOD(index,items){
      console.log('index-----',index)
      this.POD={index:index,dataSelected:items}
    },
    async getWarehouses(){
      if(this.warehouses.length === 0){
          const res = await this.$server.search("warehouses",{limit:100});
          if (res && res.content.results) {
            this.warehouses= res.content.results;
          }else this.warehouses = [];

      }
      console.log('getWarehouses-------',this.warehouses)
    }, 
    setSelectedCountries(countries){
      console.log('countries-------',countries)
      console.log('formData ware-------',this.formData.warehouses)

        this.formData.warehouses.forEach(function (ware) {

           if (!this.formData.details.find(o => o.country === ware.country)){
                this.formData.details.push({
                  country:ware.country,
                  currency:ware.currency,
                  upsells:[],
                  affiliate:{},
                  commission:0,
                  price:0,
                  warehouse:{name:ware.name,_id:ware._id,currency:ware.currency,country:ware.country,is_primary:ware.is_primary}
              })
           }
           
        }.bind(this));


        this.formData.details.forEach(function (ware) {
          if (!this.formData.warehouses.find(o => o.country === ware.country)) this.formData.details=this.formData.details.filter(function(el) { return el.country !== ware.country; }); 
        }.bind(this));  

        console.log('formData ware 33-------',this.formData.warehouses)
        console.log('formData details 33-------',this.formData.details)

    }, 
    async setTypeProduct(type){
      if(type && type === 'affiliate') await this.getWarehouses();
      if(type) this.formData.details=[];
    }, 
    removeUpsel(indexPr,indexUpsl) {
       this.formData.details[indexPr].upsells.splice(indexUpsl, 1);
    },
    addUpsell(index){
      console.log('index---',index)
      console.log('formdta---',this.formData.details[index].upsells)
      
      if(this.formData.details[index].upsells.length>0){
         const maxQuantity= this.formData.details[index].upsells.reduce((prev, current) => (prev && prev.quantity > current.quantity) ? prev : current);
         this.formData.details[index].upsells.push({quantity:maxQuantity.quantity+1,price:0,commission:0})
      }else {
        this.formData.details[index].upsells.push({quantity:2,price:0,commission:0})
      }
    },
    faq_selectedF() {
      this.$vm2.open('ModalSettings');
    },
    close() {
      this.$vm2.close('ModalSettings');
    },
    async getwharhouseId() {
      await this.$store.dispatch('wharhouse/getwharhouseId', { country: await this.warhouseSelected }).then((res) => {
        this.idWharhouse = res;
      })
    },
    async getContryW() {
      const warhouse = await this.$server.get("warehouses", { id: this.idWharhouse._id });
      this.countryW = warhouse.content.country;
    },
    async getCurrency() {
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
        this.currency = res;
      })
    },
    //  async searchSellers(value,event) {
    //    //alert('ooooooooook',"warning")
    //   //  console.log('event_____',event)
    //   //  console.log('value_____',value)
    //      const filter = {
    //       type: "Seller",
    //       limi:this.limit,
    //       name: value,
    //     };
    //     const res= await this.$server.find("users",filter);

    //    if (res.content.results) this.sellers = res.content.results;
    //    else this.sellers = [];
    //   },
    searchSellers: _.debounce(async function (search) {
      const filter = {
        type: "Seller",
        limit: this.limit,
        //countries: this.idWharhouse.country,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    }, 500),
    handleImage(e) {
      const selectImage = e.target.files[0];
      if (selectImage) this.createBase64Image(selectImage);
    },
    createBase64Image(fileObjet) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObjet);
      reader.onload = () => {
        this.formData.picture = reader.result;
      };
    },
    validateUrl(str) {
      var tarea = null;
      if (str.toString().indexOf("http://") == 0 || str.toString().indexOf("https://") == 0) {
        tarea = null;
      } else tarea = 'Veuillez saisir une Verification Link valide';
      if (!tarea && str.toString().length > 199) tarea = 'Verification Link est plus grande enter a valid url svp';

      return tarea;
    },
    async save() {
      if(this.formData.type && this.formData.type === 'affiliate') this.formData.verificationLink="https://";

      if (this.formData._id) {
          if(!this.checkSize()) return this.$toast.warning(`la taille de image doit pas dépasser 500 ko !!`);
          if(!this.checkData()) return  this.$toast.warning(this.$t('all_required_field'));
          if(this.validateUrl(this.formData.verificationLink)) return this.$toast.warning(this.validateUrl(this.formData.verificationLink));
          if((!this.formData.type || this.formData.type && this.formData.type !== "affiliate") && this.formData.name.length > 40 && this.currentUser.type === 'Seller') return this.$toast.warning('Le nom du produit ne dépasse pas 40 caracteres'); 

          this.formData.code= this.tagCodes.map(i => i.text);
          this.loading=true;
          const data = await this.$server.update("products", this.formData);
          this.loading=false;
          if (data && data._id) {
              this.$toast.success(this.$t('product_updated'))
              this.close();
          }else{
              if(data.content) alert(data.content, "error");
              else alert("There was a problem updating the product, please try again later", "error");
          }
      } else {
        if (this.checkData() && this.checkSize()) {
          if(this.validateUrl(this.formData.verificationLink)) return this.$toast.warning(this.validateUrl(this.formData.verificationLink));
          if((!this.formData.type || this.formData.type && this.formData.type !== "affiliate") && this.formData.name.length > 40 && this.currentUser.type === 'Seller') return this.$toast.warning('Le nom du produit ne dépasse pas 40 caracteres');
           
          //this.formData.quantity.total = this.formData.quantity.inStock;
          console.log('formData 1________________', this.formData);
          if((this.formData.type && ['normal','POD'].includes(this.formData.type) ) || !this.formData.type){
            if(this.formData.details.length === 0) this.formData.details=[{ price: 0,warehouse:null }];
              this.formData.details[0].warehouse=this.idWharhouse._id;
              this.formData.details[0].country=this.idWharhouse.country;
          }
          
           this.formData.code= this.tagCodes.map(i => i.text);
           console.log('formData 2________________', this.formData);
           this.loading=true;
           const data = await this.$server.create("products", this.formData);
           this.loading=false;
         console.log('dataaaaaaaaaaa',data);
          if (data && data._id) {
            this.formData = {
              name: "",
              code: "",
              price: 0,
              description: "",
              quantity: {
                inStock: 0,
                total: 0,
              },
              details: [{ price: 0 }],
              verificationLink: "",
              outOfStock: false,
              picture: "",
              seller: "",
            };
            if (this.currentUser.type == "Seller") {
              this.formData.seller = this.currentUser._id;
            }
            alert(this.$t('product_created'), "success");
          } else {
            if (data.content) alert(data.content, "error");
            else alert("There was a problem creating the product, please try again later", "error");
          }
        } else {
          if (!this.checkData()) alert(this.$t('all_required_field'), "warning");
          if (!this.checkSize()) alert(`la taille de image doit pas dépasser 500 ko !! `, "warning");
        }
      }
    },
    checkData() {
      if (this.formData.name &&
        this.formData.description &&
        this.formData.verificationLink
        // && this.formData.picture
      ) return true;
      else return false;
    },
    niceBytes(x) {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0, n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    checkSize() {
      if (this.formData.picture) {
        let base64Length = this.formData.picture.length - (this.formData.picture.indexOf(',') + 1);
        let padding = (this.formData.picture.charAt(this.formData.picture.length - 2) === '=') ? 2 : ((this.formData.picture.charAt(this.formData.picture.length - 1) === '=') ? 1 : 0);
        let fileSize = base64Length * 0.75 - padding
        let sizeImage = this.niceBytes(fileSize);
        let typeSize = sizeImage.split(' ')[1];
        if (typeSize === "bytes" || typeSize === "KB") {
          if (sizeImage.split(' ')[0] <= 500) return true;
          else return false;
        } else return false;
      } else return true;

    },
    /**************************/
    async dataToEdit() {
      this.tagCodes = [];
      if (this.$route.params.id) {
          this.loading=true;
          const editData = await this.$server.get("products", { id: this.$route.params.id,});
          this.loading=false;
          this.formData = editData.content;
          this.formData.details.forEach(pr => {
             if(!pr.upsells) pr.upsells=[];
             if(!pr.affiliate) pr.affiliate={};
          });
          
          if(this.formData.code && Array.isArray(this.formData.code)){
            for (var item in this.formData.code){
              this.tagCodes.push({ text: this.formData.code[item] });
            }
            this.formData.code.forEach(function (code) {code = {"text":code} }.bind(this));
         } 

         if(this.formData.type && this.formData.type === 'affiliate'){
              await this.getWarehouses();
              if(!this.formData.warehouses) this.formData.warehouses=[];
              this.formData.details.forEach(function (ware) {
                if (!this.formData.warehouses.find(o => o.country === ware.warehouse.country)) this.formData.warehouses.push(ware.warehouse)
              }.bind(this));

              console.log('formadata warehousesdd---',this.formData) 
         }
      }
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content.results) this.categories = res.content.results;
      else this.categories = [];
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        //countries: await this.warhouseSelected
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    async getProducts() {
      const res = await this.$server.search("products");
      if (res.content.results) this.products = res.content.results;
      else this.products = [];
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      //console.log(this.currentUser);
    },
  },
};
</script>

<style scoped>
[class*="toggle__dot-"] {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked~[class*="toggle__dot-"] {
  transform: translateX(100%);
  background-color: #63b3ed;
}

.vs__dropdown-toggle {
  border: none;
}
</style>